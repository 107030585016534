import Head from 'next/head'
import { BRAND } from '@/data/constants/BRAND'
import { ENVIRONMENT_NAME } from '@/services/Configuration'
import { useIsClientOnTheWrongDomain } from '@/hooks/useIsClientOnTheWrongDomain'

export interface MetaProps {
  title?: string,
  description?: string
}

const isProduction = ENVIRONMENT_NAME === 'production'

const Meta: React.FC<MetaProps> = ({ title = '', description = '' }) => {
  const isClientOnWrongDomain = useIsClientOnTheWrongDomain()
  const shouldShowNoIndexNoFollow = !isProduction || isClientOnWrongDomain
  return (
    <div>
      <Head>
        {shouldShowNoIndexNoFollow ? <meta name="robots" content="noindex, nofollow" /> : null}
        <title>{title || `Luxury Home, Outdoor Furniture & Decor | ${BRAND}`}</title>
        <meta
          name="description"
          content={description || `Shop luxury home
      décor &amp; stylish, chic furniture at ${BRAND}. Browse our
      collection of modern furniture, bedding, art & more!
      `}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
      </Head>
    </div>
  )
}

export default Meta
